<template>
  <div class="step1">
    <h2 class="text-center my-3 my-sm-5">Payment Method</h2>
    <b-form
      @submit.prevent="submitForm"
      class="w-100 needs-validation"
      novalidate
    >
      <div class="row mb-2 mb-lg-4">
        <div class="col-12 col-md-6">
          <div class="container">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="form.selectedPaymentMethod"
                :aria-describedby="ariaDescribedby"
                name="radio-inline"
                class="
                  paymentRadio
                  d-flex
                  justify-content-between
                  align-items-center
                "
              >
                <div
                  class="
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                  "
                >
                  <img src="../../../assets/img/svg/visa.svg" alt="" />
                  <b-form-radio
                    id="radio-1"
                    value="1"
                    class="mx-auto"
                  ></b-form-radio>
                </div>
                <div
                  class="
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                  "
                >
                  <img src="../../../assets/img/svg/mastercard.svg" alt="" />
                  <b-form-radio
                    id="radio-2"
                    value="2"
                    class="mx-auto"
                  ></b-form-radio>
                </div>
                <div
                  class="
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                  "
                >
                  <img src="../../../assets/img//svg/paypal.svg" alt="" />
                  <b-form-radio
                    id="radio-3"
                    value="3"
                    class="mx-auto"
                  ></b-form-radio>
                </div>
                <div
                  class="
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                  "
                >
                  <img
                    src="../../../assets/img//svg/bank-transfer.svg"
                    alt=""
                  />
                  <b-form-radio
                    id="radio-4"
                    value="4"
                    class="mx-auto"
                  ></b-form-radio>
                </div>
                <div
                  class="
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                  "
                >
                  <img
                    src="../../../assets/img//svg/american-express.svg"
                    alt=""
                  />
                  <b-form-radio
                    id="radio-5"
                    value="5"
                    class="mx-auto"
                  ></b-form-radio>
                </div>
                <div
                  class="
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                  "
                >
                  <img src="../../../assets/img//svg/discover.svg" alt="" />
                  <b-form-radio
                    id="radio-6"
                    value="6"
                    class="mx-auto"
                  ></b-form-radio>
                </div>
              </b-form-radio-group>
            </b-form-group>
            <div class="row">
              <div class="col-12">
                <b-form-group
                  id="input-group-1"
                  label="Select Product:"
                  label-for="input-1"
                >
                  <div class="citySelect">
                    <b-form-select
                      v-model="form.selectedProduct"
                      :options="products"
                      id="input-1"
                      aria-describedby="input-1-live-feedback"
                      :state="validateState('selectedProduct')"
                      required
                    >
                      <b-form-select-option :value="null"
                        >Vaccine Card - $10</b-form-select-option
                      >
                    </b-form-select>
                  </div>
                </b-form-group>
              </div>

              <div class="col-12 col-sm-6 col-md-12 col-lg-6">
                <b-form-group
                  id="input-group-2"
                  label="Card Type:"
                  label-for="input-2"
                >
                  <div class="inputField">
                    <b-form-input
                      id="input-2"
                      v-model="form.cardType"
                      placeholder="Lorem"
                      aria-describedby="input-2-live-feedback"
                      :state="validateState('cardType')"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-1-live-feedback"
                      >This field is required</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </div>

              <div class="col-12 col-sm-6 col-md-12 col-lg-6">
                <b-form-group
                  id="input-group-3"
                  label="Card Number:"
                  label-for="input-3"
                >
                  <div class="inputField">
                    <b-form-input
                      id="input-3"
                      type="tel"
                      maxlength="16"
                      v-model="form.cardNumber"
                      placeholder="Lorem"
                      aria-describedby="input-3-live-feedback"
                      :state="validateState('cardNumber')"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-1-live-feedback"
                      >This field is required</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </div>

              <div class="col-12 col-sm-6 col-md-12 col-lg-6 mb-3">
                <label for="datepicker-1">Expiration:</label>
                <b-form-datepicker
                  id="datepicker-1"
                  v-model="form.cardExpiration"
                  placeholder="Expires Date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="en-uk"
                  aria-describedby="input-4-live-feedback"
                  :state="validateState('cardExpiration')"
                  required
                ></b-form-datepicker>
                <b-form-invalid-feedback id="input-1-live-feedback"
                  >This field is required</b-form-invalid-feedback
                >
              </div>

              <div class="col-12 col-sm-6 col-md-12 col-lg-6">
                <b-form-group
                  id="input-group-4"
                  label="Security Code:"
                  label-for="input-4"
                >
                  <div class="inputField">
                    <b-form-input
                      id="input-4"
                      type="password"
                      maxlength="3"
                      v-model="form.cardSecurity"
                      placeholder="123"
                      aria-describedby="input-5-live-feedback"
                      :state="validateState('cardSecurity')"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-1-live-feedback"
                      >This field is required</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="container">
            <b-form-checkbox
              size="md"
              v-model="form.differentAddress"
              @change="toggleBillingAddress"
              class="mb-3"
              ><span class="checkbox-content">
                Different Billing Address</span
              ></b-form-checkbox
            >
            <div id="billingAddress">
              <b-form-group
                id="input-group-5"
                label="Mailing Address 1:"
                label-for="input-5"
              >
                <div class="inputField">
                  <b-form-input
                    id="input-5"
                    placeholder="Lorem"
                    v-model="form.mailAddress1"
                    aria-describedby="input-6-live-feedback"
                    :state="validateState('mailAddress1')"
                    required
                    ref="mailAddress1"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-1-live-feedback"
                    >This field is required</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-6"
                label="Mailing Address 2:"
                label-for="input-6"
              >
                <div class="inputField">
                  <b-form-input
                    id="input-6"
                    placeholder="Lorem"
                    v-model="form.mailAddress2"
                    aria-describedby="input-7-live-feedback"
                    :state="validateState('mailAddress2')"
                    required
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-1-live-feedback"
                    >This field is required</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-7"
                label="City:"
                label-for="input-7"
              >
                <div class="inputField">
                  <b-form-input
                    id="input-7"
                    v-model="form.city"
                    placeholder="City"
                    aria-describedby="input-7-live-feedback"
                    :state="validateState('city')"
                    ref="city"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-7-live-feedback"
                    >This field is required</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-8"
                label="State or Provincial:"
                label-for="input-8"
              >
                <div class="inputField">
                  <b-form-input
                    id="input-8"
                    v-model="form.state"
                    placeholder="State"
                    aria-describedby="input-8-live-feedback"
                    :state="validateState('state')"
                    ref="state"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-8-live-feedback"
                    >This field is required</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-9"
                label="Zip or Postal Code:"
                label-for="input-9"
              >
                <div class="inputField">
                  <b-form-input
                    id="input-9"
                    type="tel"
                    placeholder="Zip Code"
                    v-model="form.zipcode"
                    aria-describedby="input-10-live-feedback"
                    :state="validateState('zipcode')"
                    required
                    ref="zipcode"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-1-live-feedback"
                    >Please enter correct zipcode</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-10"
                label="Country:"
                label-for="input-10"
              >
                <div class="inputField">
                  <b-form-input
                    id="input-10"
                    placeholder="Country"
                    v-model="form.country"
                    aria-describedby="input-11-live-feedback"
                    :state="validateState('country')"
                    required
                    ref="country"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-1-live-feedback"
                    >This field is required</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>
            </div>

            <b-button
              type="submit"
              variant="primary"
              class="nextBtn w-100 mt-4 mb-3"
            >
              Done
            </b-button>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, numeric } from "vuelidate/lib/validators";

const axios = require("axios");
export default {
  mixins: [validationMixin],

  data() {
    return {
      form: {
        selectedPaymentMethod: "1",
        selectedProduct: null,
        cardType: "",
        cardNumber: "",
        cardExpiration: "",
        cardSecurity: "",
        differentAddress: true,
        mailAddress1: "",
        mailAddress2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
      },
      products: [
        "Vaccine Card - $10",
        "Vaccine Card - $10",
        "Vaccine Card - $10",
        "Vaccine Card - $10",
        "Vaccine Card - $10",
      ],
    };
  },
  validations: {
    form: {
      selectedProduct: {
        required,
      },
      cardType: {
        required,
      },
      cardNumber: {
        required,
        numeric,
      },
      cardExpiration: {
        required,
      },
      cardSecurity: {
        required,
      },
      mailAddress1: {
        required,
      },
      mailAddress2: {},
      city: {
        required,
      },
      state: {
        required,
      },
      zipcode: {
        required,
        numeric,
      },
      country: {
        required,
      },
    },
  },
   mounted() {
    for (let ref in this.$refs) {
      // Initialize the map.
      const input = this.$refs[ref].$el;
      const autocomplete = new google.maps.places.Autocomplete(input);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        place.address_components.forEach(element => {
 
          if(element.types[0]==="country"){
            this.form.country = element.long_name
          }
          if(element.types[0]==="administrative_area_level_1"){
            this.form.state = element.long_name
          }
          if(element.types[0]==="administrative_area_level_2"){
            this.form.city = element.long_name
          }
          if(element.types[0]==="postal_code"){
            this.form.zipcode = element.long_name
          }
        });
      });
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    toggleBillingAddress() {
      var childNodes = document
        .getElementById("billingAddress")
        .getElementsByTagName("*");
      if (this.form.differentAddress == false) {
        for (var node of childNodes) {
          node.disabled = true;
        }
      } else {
        for (var node of childNodes) {
          node.disabled = false;
        }
      }
    },
    submitForm() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$router.push({ path: "/signup-complete" });
    },
  },
};
</script>

<style lang="scss" scoped>
.step1 {
  width: 100%;
  height: 100%;

  .container {
    padding: 0 5vw;
    font-size: 0.9rem;

    .custom-checkbox {
      .checkbox-content {
        line-height: 1.5rem;
      }
    }

    .form-group {
      font-size: 0.9rem !important;
    }
    label {
      font-size: 0.9rem !important;
    }
    input {
      border-radius: 90px;
      box-shadow: 0px 5px 9px #33333326;
      border: 1px solid #ebebeb;
      padding: 0 20px;
    }

    select {
      appearance: none;
      background-color: transparent;
      border: none;
      padding: 0 10px;
      margin: 0;
      width: 100%;
      font-family: inherit;
      font-size: inherit;
      cursor: inherit;
      line-height: inherit;
      outline: none;

      &::-ms-expand {
        display: none;
      }

      &:focus,
      &:active {
        border-color: transparent;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0),
          0 0 8px rgba(102, 175, 233, 0);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0),
          0 0 8px rgba(102, 175, 233, 0);
      }
    }

    .citySelect {
      width: 100%;
      border: 1px solid var(--select-border);
      border-radius: 0.25em;
      padding: 0 0.5em;
      cursor: pointer;
      line-height: 1.1;
      border-radius: 90px;
      box-shadow: 0px 5px 9px #33333326;
      border: 1px solid #ebebeb;
    }
  }

  img {
    width: 30px;
    height: 30px;
  }

  .paymentRadio {
    padding: 5px 10px;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    box-shadow: 0px 5px 9px #33333326;
    border: 1px solid #ebebeb;
    border-radius: 10px;
  }

  .b-form-btn-label-control.form-control {
    border-radius: 90px;
    box-shadow: 0px 5px 9px #33333326;
    border: 1px solid #ebebeb;
  }

  .nextBtn {
    border-radius: 90px;
    box-shadow: 0px 5px 9px #33333326;
  }
}
</style>